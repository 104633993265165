// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-reference-detail-tsx": () => import("./../../../src/page-templates/reference-detail.tsx" /* webpackChunkName: "component---src-page-templates-reference-detail-tsx" */),
  "component---src-page-templates-service-detail-tsx": () => import("./../../../src/page-templates/service-detail.tsx" /* webpackChunkName: "component---src-page-templates-service-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-docs-image-detection-index-tsx": () => import("./../../../src/pages/docs/image-detection/index.tsx" /* webpackChunkName: "component---src-pages-docs-image-detection-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-cookies-tsx": () => import("./../../../src/pages/privacy-policy/cookies.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-cookies-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

