import { css } from '@emotion/react'
import { Theme } from 'src/style/Theme'

/**
 * Colors:
 * primary
 * secondary
 * white
 * black
 * error
 * gray
 * lightGray
 * darkGray
 */

export const createTheme = (): Theme => ({
    colors: {
        primary: '#901d1d',
        secondary: '#444444',
        white: '#ffffff',
        black: '#000000',
        error: '#901d1d',
        lightestGray: '#f5f5f5',
        lightGray: '#dddddd',
        darkGray: '#555555',
        gray: '#aaaaaa'
    },
    fonts: {
        primary: 'Raleway, sans-serif',
        secondary: 'Crete Round, serif'
    },
    fontSize: {
        xxl: '100px',
        xl: '40px',
        lg: '28px',
        md: '18px',
        sm: '14px',
        xs: '13px'
    },
    fontWeight: {
        thin: 300,
        normal: 400,
        semiBold: 600,
        bold: 700
    },
    spacing: {
        xs: '0.25rem',
        sm: '0.5rem',
        md: '1rem',
        lg: '2rem',
        xl: '3rem',
        containerDivider: '3rem'
    },
    container: {
        width: '1170px'
    },
    zIndex: {
        nav: 10
    },
    mediaQuery: {
        xs: '360px',
        sm: '480px',
        md: '768px',
        lg: '1024px',
        xl: '1600px'
    },
    brightness: {
        xs: 0.05,
        sm: 0.1,
        md: 0.15,
        lg: 0.2,
        xl: 0.25
    },
    borderRadius: {
        sm: '3px'
    },
    headerHeight: '80px'
})

export const createGlobalCss = (th: Theme) => css`
    body {
        font-family: ${th.fonts.primary};
        color: ${th.colors.secondary};
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        * {
            font-family: ${th.fonts.primary};
        }
    }
    a {
        color: ${th.colors.primary};
        text-decoration: none;
        &:hover {
            color: ${th.colors.black};
        }
    }

    p {
        line-height: 1.8;
        font-size: ${th.fontSize.sm};
        color: ${th.colors.darkGray};
        margin: 0 0 ${th.spacing.md};
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ::-moz-selection {
        color: ${th.colors.white};
        background: ${th.colors.primary};
    }

    ::selection {
        color: ${th.colors.white};
        background: ${th.colors.primary};
    }
`
