import React from 'react'
import { createGlobalCss, createTheme } from 'src/style/styles'
import { Global, ThemeProvider } from '@emotion/react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
export const wrapPageElement = ({ element, props }) => element

const theme = createTheme()
const globalCss = createGlobalCss(theme)

export const wrapRootElement = ({ element }) => (
    <div>
        <QueryClientProvider client={queryClient}>
            <ParallaxProvider>
                <Global styles={globalCss} />
                <ThemeProvider theme={theme}>{element}</ThemeProvider>
            </ParallaxProvider>
        </QueryClientProvider>
    </div>
)

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === 'undefined') {
        await import('intersection-observer')
    }
}
